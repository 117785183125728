import { render, staticRenderFns } from "./xzsbgl.vue?vue&type=template&id=21c9aeb2&scoped=true"
import script from "./xzsbgl.vue?vue&type=script&lang=js"
export * from "./xzsbgl.vue?vue&type=script&lang=js"
import style0 from "./xzsbgl.vue?vue&type=style&index=0&id=21c9aeb2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c9aeb2",
  null
  
)

export default component.exports